import React, { Component } from 'react'
import { Link } from 'gatsby'
import XeroLogo from '../assets/images/seiva-xero-logo.png'
import { Arrow } from './icons.js'
import addToMailchimp from 'gatsby-plugin-mailchimp'

class Footer extends Component {

  state = { 
    result: '',
    msg: ''
  };

  _handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  // 2. via `async/await`
  _handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(this.state.email, {
      FNAME: this.state.fname,
      LNAME: this.state.lname
    })
    this.setState({
      result: result.result,
      msg: result.msg
    })
  }

  render() {
    return (
      <section className="footer">
        <div className="footer__body container">
            <div className="footer__body__subform">
              <h4>Fill your inbox with something other than bills</h4>
              { this.state.result === '' &&
                <form
                  className="form form--contact" 
                  name="subscribe" 
                  onSubmit={this._handleSubmit}>
                  <div className="form__row">
                    <input type="text" name="fname" placeholder="First Name" onChange={this._handleChange} /> 
                  </div>
                  <div className="form__row">
                    <input type="text" name="lname" placeholder="Last Name" onChange={this._handleChange} /> 
                  </div>
                  <div className="form__row">
                    <input type="email" name="email" placeholder="Email" onChange={this._handleChange} /> 
                  </div> 
                  <div className="form__row form__row--submit">
                    <button className="btn btn--secondary" type="submit">Subscribe <Arrow /></button>
                  </div>
                </form>
              }
              { this.state.result !== '' &&
                <div className="form__form-wrapper">
                  <p className="result" dangerouslySetInnerHTML={{ __html: this.state.msg }} />
                </div>
              }
            </div>
            <div className="footer__body__find">
              <h4>Find Us</h4>
              <ul>
                <li>Lvl 1, 17-19 Chessell St</li>
                <li>Southbank, VIC 3006</li>
                <li>Monday - Friday</li>
                <li>8:30am - 5pm</li>
              </ul>
              <p className='footer__liability'>Liability limited by a Scheme approved under Professional Standards Legislation</p>
            </div>
            <div className="footer__body__chat">
            <h4>Chat to Us</h4>
              <ul>
                <li>On the phone</li>
                <li><a href="tel:+61398666222">(03) 9866 6222</a></li>
                <li>On the computer</li>
                <li><a href="mailto:admin@seiva.com.au">admin@seiva.com.au</a></li>
              </ul>
            </div>
            <div className="footer__body__social">
            <h4>Social</h4>
              <div className="footer__social-mobile">
                <ul>
                  <li><a href="https://www.instagram.com/seivabusiness/" target="_blank" rel="noopener noreferrer">Instagram <Arrow /></a></li>
                  <li><a href="https://www.facebook.com/SEIVAbusiness/" target="_blank" rel="noopener noreferrer">Facebook <Arrow /></a></li>
                  <li><a href="https://www.linkedin.com/company/seiva-business/" target="_blank" rel="noopener noreferrer">LinkedIn <Arrow /></a></li>
                  <li></li>
                </ul>
                <div> 
                  <img className="xero" src={XeroLogo} alt="Xero Platinum Partner" />
                </div>
              </div>
            </div>
        </div>

        <div className="footer__bottom">
          <div className="container">
            <div className="footer__bottom footer__bottom--left">
                <span>© SEIVA 2021</span>
            </div>
            <div className="footer__bottom footer__bottom--right">
              <Link to="/privacy-policy">Privacy policy</Link>
              <Link to="/terms-of-use">Terms of use</Link>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

Footer.defaultProps = {
  siteTitle: `Seiva`,
}

export default Footer